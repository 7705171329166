/*////////////////// font //////////////////*/



@font-face {
  font-family: 'GT Walsheim Regular';
  src: url('./assets/font/GT-Walsheim-Regular-Trial.otf') format('opentype');
}

@font-face {
  font-family: 'GT Walsheim Bold';
  src: url('./assets/font/GT-Walsheim-Bold-Trial.otf') format('opentype');
}

@font-face {
  font-family: 'GT Walsheim Medium';
  src: url('./assets/font/GT-Walsheim-Medium-Trial.otf') format('opentype');
}

@font-face {
  font-family: 'Silka Regular';
  src: url('./assets/font/Silka-Regular.otf')
}

@font-face {
  font-family: 'Silka Medium';
  src: url('./assets/font/Silka-Medium.otf')
}

@font-face {
  font-family: 'Silka Bold';
  src: url('./assets/font/Silka-Bold.otf')
}

@font-face {
  font-family: 'Silka Regular Italic';
  src: url('./assets/font/Silka-RegularItalic.otf')
}



/*////////////////// font //////////////////*/



img{
  display: block;
}

html {
  box-sizing: border-box;
  /* scroll-behavior: smooth; */
}
*, *:before, *:after {
  box-sizing: inherit;
}

.App {  
  color: #323232;
}

a{
  text-decoration: none;
}

.h1 {
  font-size: 48px;
  font-weight: 700;
  font-family: 'GT Walsheim Bold', Helvetica, Arial, sans-serif;
}

.h2 {
  font-size: 28px;
  font-weight: 700;
  font-family: 'GT Walsheim Bold', Helvetica, Arial, sans-serif;
}

.h3 {
  font-size: 24px;
  font-weight: 700;
  font-family: 'GT Walsheim Bold', Helvetica, Arial, sans-serif;
  line-height: 28px;
}

.h4 {
  font-size: 18px;
  line-height: 1.4;
  font-weight: 500;
  font-family: 'Silka Medium', Helvetica, Arial, sans-serif;
}

.h5 {
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600;
  font-family: 'Silka Medium', Helvetica, Arial, sans-serif;
}

.p1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  font-family: 'Silka Regular', Helvetica, Arial, sans-serif;
}

.p2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  font-family: 'Silka Regular', Helvetica, Arial, sans-serif;
  letter-spacing: -0.004em;
}

.p2-home {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  font-family: 'GT Walsheim Regular', Helvetica, Arial, sans-serif;
  letter-spacing: -0.004em;
}

.caption1 {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.4;
  font-family: 'GT Walsheim Regular', Helvetica, Arial, sans-serif;
}

.caption2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  text-transform: uppercase;
  text-decoration: underline;
  text-decoration-color:#c4c4c4;
  font-family: 'GT Walsheim Regular', Helvetica, Arial, sans-serif;
}

.span-bold{
  font-weight: 700;
  font-family: 'Silka Bold', Helvetica, Arial, sans-serif;
}

.span-medium{
  font-weight: 600;
  font-family: 'Silka Medium', Helvetica, Arial, sans-serif;
}

.default-color {
  color: #323232;
}

.text-grey {
  color: #777777;
}

.background-g1{
  background-color: #f4f4f4;
}

.background-g2{
  background-color: #f8f8f8;
}

.header {
  width: 100%;
  background-color: white;
  height: 48px;  
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.14);
  position: sticky;
  top: 0;
  z-index: 10;
}

.header-list {
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 24px;
  justify-content: space-between;
}

.footer {
  width: 1080px;
  height: 276px;
  margin: 96px auto;
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 64px;
}

.homepage {
}

.header-list-item {
  border-radius: 8px;
  margin-right: 24px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  height: 32px;
  display: inline-flex;
  padding: 0 8px 0 8px;
  align-items: center;
  transition: 0.3s ease-in-out;
  font-family: 'GT Walsheim Bold', Helvetica, Arial, sans-serif;
}
.header-list-item:hover {
  background-color: #f4f4f4;
  /* box-shadow: inset 0 0 0 1px #f0f0f0; */
}


.homepage-about {
  width: 1280px;
  margin: 24px auto 0 auto;
  height: 640px;
  position: relative;
  padding-top: 96px;
  padding-left: 140px;
}

.homepage-canvas {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  border-radius: 64px;
}

.homepage-about-text {
  width: 720px;
  display: block;
}

.homepage-projects {
  width: 1080px;
  margin: auto;
}

.homepage-bio {
  padding-top: 48px;
  width: 100%;
  background-image: linear-gradient(#f4f4f4, #f4f4f4);
  padding-bottom: 48px;
}

.homepage-bio-b {
  width: 1080px;
  display: flex;
  padding: 48px;
  justify-content: space-between;
  margin: auto;
  border-radius: 48px;
  background-color: white;
}

.homepage-bio-p {
  width: 296px;
}

.project-tile {
  width: 528px;
  height: 528px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.article-tile {
  width: 528px;
  height: 252px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.prototype-tile {
  width: 252px;
  height: 252px;
  padding: 16px;
  position: relative;
}

.tile {
  background: #FFFFFF;
  border-radius: 48px;
  box-shadow: 0 0 0 1px #e4e4e4;
  transition: 0.3s ease-in-out;
}

.tile:hover {
  box-shadow: 0 0 0 4px #D1CFF2;
}

.project-hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-1080 {
  width: 1080px;
}

.project-textblock-2{
  width: 516px;
}


.flex-between {
  display: flex;
  justify-content: space-between;
}


.fullsize-greytowhite {
  width: 100%;
  padding-top: 48px;
  padding-bottom: 48px;
  background-image: linear-gradient(#f8f8f8, #ffffff);
}

.darkmode-bg{
  background-color: #000000;
}

.text-default-dm{
  color: #ffffff;
}

.text-grey-dm{
  color: #717579;
}

.data-number{
  font-family: 'GT Walsheim Bold', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  background: linear-gradient(90deg, #415EF4 0%, #8798F1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.data-card{
  width: 576px;
  height: 360px;
  padding: 48px;
  background-color: #f8f8f8;
  /* transition: ease-in-out 0.3s; */
  box-shadow: 0 0 0 1px #f8f8f8;
}

.data-card:hover {
  /* border-radius: 48px; */
  background-color: white;
  box-shadow: 0 0 0 1px #e4e4e4;
}

.slider {
  width: 640px;
  height: 72px;
  margin: 0 auto;
  display: flex;
}

.slider-item{
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7562B6;
  font-weight: 700;
  font-family: 'GT Walsheim Bold', Helvetica, Arial, sans-serif;
  background-color: white;
  font-size: 24px;
  cursor: pointer;
}

.slider-item:hover {
  background-color: #E5E5F2;
}

.slider-selected{
  background-color: #E5E5F2;
}

.slidein-animation{
  animation-duration: 0.4s;
  animation-name: slidein;
  animation-timing-function: ease-in-out;
}

@keyframes slidein {
  from {
    margin-top: 48px;
    opacity: 0;
  }

  to {
    margin-left: 0;
    opacity: 1;
  }
}

.border-light {
  border-radius: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
}

.exercise-wireframe {
  background-color: #fafafa;
  border-radius: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  border-radius: 32px;
  padding: 16px;
  overflow: scroll;
}


.problem-statement {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  font-family: 'Silka Regular Italic', Helvetica, Arial, sans-serif;
  font-style: italic;
}







/* utility classes */




.m-left-4 {
  margin-left: 4px;
}

.m-left-8 {
  margin-left: 8px;
}

.m-left-12 {
  margin-left: 12px;
}

.m-left-16 {
  margin-left: 16px;
}

.m-left-24 {
  margin-left: 24px;
}

.m-left-32 {
  margin-left: 32px;
}

.m-left-40 {
  margin-left: 40px;
}

.m-left-48 {
  margin-left: 48px;
}

.m-left-64 {
  margin-left: 64px;
}

.m-left-96 {
  margin-left: 96px;
}

.m-left-144 {
  margin-left: 144px;
}

.m-right-4 {
  margin-right: 4px;
}

.m-right-8 {
  margin-right: 8px;
}

.m-right-12 {
  margin-right: 12px;
}

.m-right-16 {
  margin-right: 16px;
}

.m-right-24 {
  margin-right: 24px;
}

.m-right-32 {
  margin-right: 32px;
}

.m-right-40 {
  margin-right: 40px;
}

.m-right-48 {
  margin-right: 48px;
}

.m-right-64 {
  margin-right: 64px;
}

.m-right-96 {
  margin-right: 96px;
}

.m-right-144 {
  margin-right: 144px;
}

.m-top-4 {
  margin-top: 4px;
}

.m-top-8 {
  margin-top: 8px;
}

.m-top-12 {
  margin-top: 12px;
}

.m-top-16 {
  margin-top: 16px;
}

.m-top-24 {
  margin-top: 24px;
}

.m-top-32 {
  margin-top: 32px;
}

.m-top-40 {
  margin-top: 40px;
}

.m-top-48 {
  margin-top: 48px;
}

.m-top-64 {
  margin-top: 64px;
}

.m-top-96 {
  margin-top: 96px;
}

.m-top-144 {
  margin-top: 144px;
}

.m-bottom-4 {
  margin-bottom: 4px;
}

.m-bottom-8 {
  margin-bottom: 8px;
}

.m-bottom-12 {
  margin-bottom: 12px;
}

.m-bottom-16 {
  margin-bottom: 16px;
}

.m-bottom-24 {
  margin-bottom: 24px;
}

.m-bottom-32 {
  margin-bottom: 32px;
}

.m-bottom-40 {
  margin-bottom: 40px;
}

.m-bottom-48 {
  margin-bottom: 48px;
}

.m-bottom-64 {
  margin-bottom: 64px;
}

.m-bottom-96 {
  margin-bottom: 96px;
}

.m-bottom-144 {
  margin-bottom: 144px;
}

.m-auto {
  margin-left: auto;
  margin-right: auto;
}

.con-516 {
  width: 516px; 
}

.con-540 {
  width: 540px; 
}

.con-576 {
  width: 576px; 
}

.con-720 {
  width: 720px; 
}

.con-960 {
  width: 960px;
}

.con-1080 {
  width: 1080px;
}

.con-1200 {
  width: 1200px;
}

.con-1176 {
  width: 1176px;
}

.con-1280 {
  width: 1280px;
}

.text-center {
  text-align: center;
}

.s-1{
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
}

.s-2{
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
}

.s-3{
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
}

.r-4{
  border-radius: 4px;
}

.r-8{
  border-radius: 8px;
}

.r-48 {
  border-radius: 48px;
}

.r-64 {
  border-radius: 64px;
}

.r-32 {
  border-radius: 32px;
}