/*////////////////// font //////////////////*/
@font-face {
    font-family: 'GT Walsheim Regular';
    src: url('./assets/font/GT-Walsheim-Regular-Trial.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'GT Walsheim bold';
    src: url('./assets/font/GT-Walsheim-Bold-Trial.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'GT Walsheim medium';
    src: url('./assets/font/GT-Walsheim-Medium-Trial.otf') format('opentype');
  }
  /*////////////////// font //////////////////*/

.ui-tag{
    height: 32px;
    display: inline-flex;
    align-items: center;
    padding: 0 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.3;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    background-color: white;
    font-family: 'GT Walsheim Medium';
}

.logoicon{
    display: flex;
    height: 32px;
    width: 32px;
    align-items: center;
    justify-content: center;
    /* border: 1px solid #E4E4E4; */
    border-radius: 12px;
    margin-right: 16px;
    background-color: white;
    transition: 0.3s ease-in-out;
    box-shadow: 0 0 0 1px #e4e4e4;
}

.logoicon:hover {
    box-shadow: 0 0 0 2px #D1CFF2;
}

.ui-link{
    position:relative;
    display: inline-block;
    color: #323232;
}

.ui-link-text{
    position: relative;
    z-index: 1;
}

.ui-link-underline{
    position: absolute;
    height: 2px;
    background: #DDDBFF;
    bottom:0;
    left:0;
    transition: 0.3s;
    width: -webkit-calc(100% + 4px);
    width: -moz-calc(100% + 4px);
    width: calc(100% + 4px);
    transform: translateX(-2px);
    border-radius: 4px;
}

.ui-link:hover > .ui-link-underline {
    height: 100%;
}

.ui-button{
    height: 48px;
    background-color: rgb(89, 125, 216);
    color: white;
    font-size: 18px;
    font-weight: 500;
    display: inline-flex;
    padding: 0 16px;
    align-items: center;
    cursor: pointer;
    border-radius: 12px;
    text-decoration: none;
    font-family: "GT Walsheim medium'";
}

.ui-button:hover {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.16), 0px 4px 8px 0px rgba(89, 125, 216, 0.16);
}